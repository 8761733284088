import React, { useEffect, useState } from 'react'
import { Link, navigate } from 'gatsby'
// Components
import {
  Basic as LoadMoreButton,
  Loading,
  OrderCard,
  PageHeader,
  Primary as OrderButton,
  Seo,
  StyledSelect,
} from '../components'
// Context
import { useAuthContext } from '../context/AuthContext'
import { usePrismic } from '../context/PrismicContext'
// Styles
import { Wrapper, Container } from '../styles/common'
// Hooks
import useOrderHistory from '../hooks/useOrderHistory'
import {
  HistoryContainer,
  HistoryLabel,
} from '../../styles/OrderHistory/OrderHistory.styles'
import { MagentoHistory } from '../services/magento'
//Utils
import { getStoreView } from '../utils/i18n/stores'

const OrderHistoryPage = () => {
  const { isAuthenticated, isAuthLoading } = useAuthContext()

  const [selectedOrderType, setOrderType] = useState('stanord')

  const {
    ordersData,
    ordersPageInfo,
    isLoadingOrders,
    isLoadingMoreOrders,
    handleGetPageDataAndOrders,
    handleGetNextPageOrders,
    refreshOrderData,
  } = useOrderHistory()

  const {
    prismicData: {
      prismicOrderHistoryPage: {
        end_of_orders,
        load_more,
        loading_orders,
        no_orders,
        title,
        your_orders,
      },
      prismicCartPage: { keep_shopping },
    },
  } = usePrismic()

  useEffect(() => {
    if (isAuthLoading) return
    switch (selectedOrderType) {
      case 'standord':
        refreshOrderData()
        MagentoHistory.User.setStoreView(
          getStoreView(localStorage, navigator.language).store_code
        )
        break
      case 'subord':
        refreshOrderData()
        MagentoHistory.User.setStoreView(
          getStoreView(localStorage, navigator.language)?.autoship_store_code
        )
        break
      case 'eventord':
        refreshOrderData()
        MagentoHistory.User.setStoreView('Event')
        break
      default:
        MagentoHistory.User.setStoreView(
          getStoreView(localStorage, navigator.language).store_code
        )
    }
    isAuthenticated ? handleGetPageDataAndOrders(selectedOrderType) : navigate('/')
  }, [isAuthLoading, isAuthenticated, selectedOrderType])

  const isLastPage = ordersPageInfo?.current_page === 1

  const orderOptions = [
    { title: 'Standard Orders', key: 'standord' },
    { title: 'Subscription Orders', key: 'subord' },
    { title: 'Event Orders', key: 'eventord' },
  ]

  if (isLoadingOrders || isAuthLoading)
    return (
      <Loading
        loading={isLoadingOrders || isAuthLoading}
        message={loading_orders[0].text}
      />
    )

  return (
    <>
      <Seo title={title[0].text} />
      <Wrapper>
        <PageHeader exitRoute="/">
          <HistoryContainer>
            <HistoryLabel>Show:</HistoryLabel>
            <StyledSelect
              value={selectedOrderType}
              onChange={e => setOrderType(e.target.value)}
            >
              {orderOptions.map(item => (
                <option key={item.key} value={item.key}>
                  {item.title}
                </option>
              ))}
            </StyledSelect>
          </HistoryContainer>
        </PageHeader>

        {!ordersData?.length ? (
          <Container margin="4em 0 0 0" align="center" justify="center">
            <h1>{no_orders[0].text}</h1>

            <OrderButton data-qa="orderButton" as={Link} to="/products">
              {keep_shopping[0].text}
            </OrderButton>
          </Container>
        ) : (
          <Container>
            {!isLoadingOrders
              ? ordersData.map((order, number) => (
                  <OrderCard key={number} {...order} />
                ))
              : null}
            {isLastPage ? (
              <Container margin="4em 0" align="center" justify="center">
                <h1>{end_of_orders[0].text}</h1>
              </Container>
            ) : (
              <LoadMoreButton
                style={{ alignSelf: 'center' }}
                onClick={handleGetNextPageOrders}
                loading={isLoadingMoreOrders}
                data-qa="loadMoreButton"
              >
                {load_more[0].text}
              </LoadMoreButton>
            )}
          </Container>
        )}
      </Wrapper>
    </>
  )
}

export default OrderHistoryPage
