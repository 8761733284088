import styled from 'styled-components'

export const HistoryContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`
export const HistoryLabel = styled.div`
  font-size: 16px;
  padding: 5px;

  @media ${({ theme }) => theme.device.sm} {
    font-size: 19px;
  }
`
